import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './Component/Header';
import Services from './Component/Services';
import About from './Component/About';
import Footer from './Component/Footer';
import WhyUs from './Component/WhyUs';
import Gallery from './Component/Gallery';
import Carasoul from './Component/Carasoul';
import ContactUs from './Component/ContactUs';
import Signup from './Component/Signup';
import LoadingIndicator from './Component/LoadingIndicator'; // Import the loading indicator
import PrivacyPolicy from './Component/PrivacyPolicy';
import RefundPolicy from './Component/RefundPolicy';
import TermsAndConditions from './Component/TermsAndConditions';
import CancellationPolicy from './Component/CancellationPolicy';

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    // Listen to route changes
    handleStart();
    const timeout = setTimeout(handleComplete, 500); // Simulate a loading time
    return () => clearTimeout(timeout); // Cleanup the timeout
  }, [location]);

  return (
    <div className="App">
      {loading ? ( // Show loading indicator while loading
        <LoadingIndicator />
      ) : (
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/why-us" element={<WhyUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/carousel" element={<Carasoul />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/cancellation-policy" element={<CancellationPolicy />} />
          </Routes>
        </main>
      )}
    </div>
  );
}

const Home = () => {
  return (
    <div>
      <Header />
      <div className='logo'>
      <h1>Welcome to JM and Associate</h1>
      </div>
      <Services />
      <About />
      <WhyUs />
      <Gallery />
      <Carasoul />
      <Signup />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
