import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaClock } from 'react-icons/fa';
import './ContactUs.css';

function ContactUs() {
  return (
    <Container className="contact-us">
      <h2 className="text-center mb-4">Contact Us</h2>
      <Row className="icon-row text-center">
        <Col md={3}>
          <FaMapMarkerAlt className="contact-icon" />
        </Col>
        <Col md={3}>
          <FaEnvelope className="contact-icon" />
        </Col>
        <Col md={3}>
          <FaPhone className="contact-icon" />
        </Col>
        <Col md={3}>
          <FaClock className="contact-icon" />
        </Col>
      </Row>
      <Row className="text-row text-center mt-3">
        <Col md={3}>
          <h5>Branch Office</h5>
          <p>302 Aarja Square, Near Gaur City Mall, Gaur City, Noida Extension, Noida, Uttar Pradesh 201305</p>
        </Col>
        <Col md={3}>
          <h5>General Enquiries</h5>
          <p>Sales - <a href="sales@jmandassociate.in">sales@jmandassociate.in</a></p>
          <p>Support - <a href="insurance@thronepe.in">insurance@thronepe.in</a></p>
        </Col>
        <Col md={3}>
          <h5>Call Now</h5>
          <p>+91-9355753843</p>
        </Col>
        <Col md={3}>
          <h5>Our Timings</h5>
          <p>Mon - Fri: 10:00 AM - 05:00 PM</p>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
