// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCBOF8QcFrqwc9hkIIjnC1l6l0n6ng6Kg4",
  authDomain: "gminsurence.firebaseapp.com",
  projectId: "gminsurence",
  storageBucket: "gminsurence.appspot.com",
  messagingSenderId: "959515261154",
  appId: "1:959515261154:web:bc5596d9153b852b0106bb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc };
