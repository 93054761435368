import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { db, collection, addDoc } from "../firebae";
import { useNavigate } from 'react-router-dom';
import './Signup.css'; // Import the CSS file for styling

function Signup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setLoading(true);
      try {
        const docRef = await addDoc(collection(db, "contacts"), {
          name,
          email,
          phone,
          message,
          timestamp: new Date(),
        });
        console.log("Document written with ID: ", docRef.id);
        setShowModal(true);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      } catch (error) {
        console.error("Error adding document: ", error);
      }
      setLoading(false);
    }
    setValidated(true);
  };

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };
  
  return (
    <div className="enquiry-form-container">
      <Form className="enquiry-form" noValidate validated={validated} onSubmit={handleSubmit}>
        <h2>Enquiry Form</h2>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your full name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              required
              type="tel"
              placeholder="Enter Mobile Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid mobile number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <Form.Label>Email Address</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              <Form.Control
                type="email"
                placeholder="Enter Email Address"
                aria-describedby="inputGroupPrepend"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        
        <Row className="mb-3">
          <Form.Group as={Col} md="8" controlId="validationCustom03">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a message.
            </Form.Control.Feedback>
          </Form.Group>
          <Col className="d-flex align-items-center">
            <Button type="submit" className="btn-submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Submission Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <h5>Your submission details:</h5>
          <p><strong>Name:</strong> {name}</p>
          <p><strong>Email:</strong> {email}</p>
          <p><strong>Mobile No:</strong> {phone}</p>
          <p><strong>Message:</strong> {message}</p>
          <p style={{ color: "#4caf50", fontWeight: "bold" }}>
            Name, Email, Mobile No., and Message have been submitted successfully.
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button variant="success" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Signup;
