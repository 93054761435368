import React, { useState } from 'react';
import './Services.css';
import { Button, Modal } from 'react-bootstrap';
import { collection, addDoc,db} from '../firebae';
import car from './images/car.jpg';
import health from './images/health.jpg';
import halthagent from './images/halthagent.jpg';
import health2 from './images/health2.jpg';
import car2 from './images/car2.jpg';
import medical from './images/medical.jpg';
import insurence from './images/insurence.jpg';
import life from './images/life.jpg';
import consultion from './images/consultion.jpg';
import installtion from './images/installtion.jpg';


// Import other images...

const servicesList = [
  { 
    title: 'Vechile Insurance', 
    description: 'We offer the best insurance plans for your cars and are one of the trusted and reliable agents.',
    image: car
  },
  { 
    title: 'Group Health Insurance', 
    description: 'We are one of the best group health insurance agents presenting the most profitable insurance plans.',
    image: health
  },
  { 
    title: 'Health Insurance  - Bajaj Allianz Life', 
    description: 'We are an efficient team of expert health insurance agents for ICICI Lombard at the most reasonable deals.',
    image: halthagent
  },
  { 
    title: 'Health Insurance  - Religare', 
    description: 'We are one of the recognized and most popular health insurance agents of Religare with reliable services.',
    image: health2 
  },
  { 
    title: 'Family Insurance Plan - Star Health', 
    description: 'Star Health has a set of insurance agents that provide its customers with the most beneficial schemes.',
    image: car2
  },
  { 
    title: 'Life Insurance & Term Insurance ', 
    description: 'Our trained insurance advisors help you in comparing all life insurance policies & Term plans available in the market.',
    image: medical
  },
  { 
    title: 'Medical Insurance  - HDFC ERGO', 
    description: 'Star Health has the best medical insurance agents that provide the most profitable schemes.',
    image: insurence
  },
  { 
    title: 'Mediclaim ', 
    description: 'Stay insured with mediclaim insurance services. Contact us for further information and guidance.',
    image: life
  },
  { 
    title: 'Consultation', 
    description: 'Owing to our huge industrial knowledge and rich information, we are offering our customers with the best consultancy services.',
    image: consultion
  },
  { 
    title: 'Door Step Services , Urban & Rural Areas', 
    description: 'To meet the growing requirement of our precious consumers, Our Agent Will Provide All Services to Your Home.',
    image: installtion
  },
];

const Services = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });

  const handleEnquiryClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({ name: '', email: '', mobile: '' }); // Reset form data on close
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save form data to Firestore
      await addDoc(collection(db, 'enquiries'), formData);
      console.log('Enquiry submitted:', formData);
      handleCloseModal(); // Close the modal after submission
    } catch (error) {
      console.error('Error submitting enquiry:', error);
    }
  };

  return (
    <section className="services">
      <h2>Our Services</h2>
      <div className="services-list">
        {servicesList.map((service, index) => (
          <div key={index} className="service-item">
            <img src={service.image} alt={service.title} className="service-image" />
            <div className="service-details">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <Button className="enquiry-button" onClick={handleEnquiryClick}>Enquiry Now</Button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for Enquiry Form */}
      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name:</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="mobile" className="form-label">Mobile No:</label>
              <input
                type="text"
                name="mobile"
                id="mobile"
                className="form-control"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <Button type="submit" variant="primary">Submit</Button>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Services;
