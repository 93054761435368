import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact-us">
        <h3>Contact Us</h3>
        <p>Registered Office: C- 615 Ithum Tower<br/> Sector - 62, Noida, Uttar Pradesh 201301</p>
        <p>Sales Enquiry: sales@jmandassociate.in</p>
        <p>Customer Support: insurance@thronepe.in</p>
        <p>Call Us: +91-9355753843</p>
      </div>
      
      <div className="policies">
        <h2>Policies</h2>
        <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
        <Link to="/refund-policy" className="footer-link">Refund Policy</Link>
        <Link to="/terms-and-conditions" className="footer-link">Terms and Conditions</Link>
        <Link to="/cancellation-policy" className="footer-link">Cancellation Policy</Link>
      </div>
      
      <div className="rights">
        <p>© Copyrights 2022 - 2025 Thronepe Communications Pvt Ltd. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
