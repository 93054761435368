import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import backgroundImage from './images/backgroundImag.jpg'; 

const Header = () => {
  const location = useLocation(); // Get the current route location

  return (
    <header className="header" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="login-signup">
          <Link to="/signup" className="nav-link">Log In</Link>
          <span className="divider">|</span>
          <Link to="/signup" className="nav-link">Sign Up</Link>
      
        </div>
      <div className="contact-info">
        <span className="contact-details">+91-9355753843 | sales@jmandassociate.in</span>
        <div className='contactinfo2'>
          <span className="agent-code">Agent OU Code (IRDAI) - BA0000825054</span>
        </div>
      </div>
      <div className="logo h2">
        <h2>Welcome to JM and Associate<br/>Joint Venture of ThronePe</h2>
      </div>
      <nav className="nav">
        <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
        <Link to="/services" className={`nav-link ${location.pathname === '/services' ? 'active' : ''}`}>Services</Link>
        <Link to="/about" className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`}>About</Link>
        <Link to="/why-us" className={`nav-link ${location.pathname === '/why-us' ? 'active' : ''}`}>Why Us</Link>
        <Link to="/gallery" className={`nav-link ${location.pathname === '/gallery' ? 'active' : ''}`}>Gallery</Link>
        <Link to="/carousel" className={`nav-link ${location.pathname === '/carousel' ? 'active' : ''}`}>Carousel</Link>
        <Link to="/contact" className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact Us</Link>
      </nav>
    </header>
  );
};

export default Header;
